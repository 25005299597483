import { graphql, PageProps, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { first, last } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Button } from '../components/Button'
import Container from '../components/Container'
import Footer from '../components/Footer/Footer'
import Intro from '../components/Intro'
import PDFViewer from '../components/PDFViewer'
import { Site } from '../components/Site'
import { Main } from '../components/Styled'
import { SEO } from '../SEO'

const PageContent = styled.div`
  h1,
  h3 {
    margin: 0;
    text-align: center;

    font-weight: 500;
    font-size: 2.25rem;
    letter-spacing: -1.5px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.purple};
    ${({ theme }) => theme.breakpoints.mobile} {
      font-size: 1.75rem;
    }
  }

  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.2;
    letter-spacing: -0.5px;
    padding-top: 0.5rem;
    ${({ theme }) => theme.breakpoints.mobile} {
      font-size: 1.25rem;
    }
  }

  .cta {
    text-align: center;
    font-weight: 500;
    font-size: 1.25rem;
    margin: 0 auto;
    max-width: 50ch;
    ${({ theme }) => theme.breakpoints.mobile} {
      font-size: 1.125rem;
      line-height: 1.2;
    }
  }

  .first {
    ${({ theme }) => theme.breakpoints.mobile} {
      padding-bottom: 2rem;
    }
  }

  section {
    display: grid;
    gap: 1rem;
    margin-top: 1.5rem;
    align-items: center;
    border-radius: 3rem;
    padding: 3rem 1.5rem;
    background: ${({ theme }) => theme.colors.purple100};
    button {
      margin: 0 auto;
      max-width: 9rem;
    }
  }
`

function sanitizeChannel(channel?: string | null): 'linkedin' | 'email' {
  const path = last(channel?.split('=')) ?? 'linkedin'
  return path === 'linkedin' ? 'linkedin' : 'email'
}

const Thankyou: React.FC<PageProps> = props => {
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const param = props?.location?.search && props.location.search.match(/channel=\w+/)
  const channel = sanitizeChannel(first(param))

  const trackEvent = () =>
    trackCustomEvent({
      category: 'daisee E-book',
      action: 'Download',
      label: `Channel - ${channel}`,
    })

  const { cover, pdf } = useStaticQuery(graphql`
    query Thankyou {
      cover: file(relativePath: { eq: "e-book.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
      pdf: file(relativePath: { eq: "daisee-e-book.pdf" }) {
        publicURL
      }
    }
  `)

  const toggleIsViewOpen = () => setIsViewerOpen(prevState => !prevState)

  const pageProps: PageContentProps = {
    loading,
    isViewerOpen,
    trackEvent,
    cover: cover.childImageSharp.fluid,
    pdf: pdf.publicURL,
    toggleIsViewOpen,
  }

  return (
    <Site seo={{ ...SEO.thankyou, image: cover.publicURL }} {...props}>
      <Main>{channel === 'linkedin' ? <Linkedin {...pageProps} /> : <Email {...pageProps} />}</Main>
      <Footer />
    </Site>
  )
}

type PageContentProps = {
  loading: boolean
  isViewerOpen: boolean
  cover: FluidObject
  pdf: string
  trackEvent: () => void
  toggleIsViewOpen: () => void
}

function Linkedin({ loading, isViewerOpen, cover, pdf, trackEvent, toggleIsViewOpen }: PageContentProps) {
  const theme = useTheme()

  return (
    <>
      <Intro bgColor='purple100'>
        <Container>
          <PageContent>
            <h1>Thank you!</h1>
            <h2>We’ll be in touch by email within 1-2 business days</h2>
          </PageContent>
        </Container>
      </Intro>
      <Container>
        <PageContent>
          <section>
            <h3>In the meantime</h3>
            <p className='cta'>
              Learn more about the impact daisee’s Technology will create in your operation by reading our short e-book
            </p>
            <Button action='Open E-Book' btnHeight='auto' bgColor={theme.colors.green} onClick={toggleIsViewOpen} />
            <Img imgStyle={{ objectFit: 'contain' }} fluid={cover} />
            {isViewerOpen && <PDFViewer pdfPath={pdf} onDownload={trackEvent} onClose={toggleIsViewOpen} />}
          </section>
        </PageContent>
      </Container>
    </>
  )
}

function Email({ loading, isViewerOpen, cover, pdf, trackEvent, toggleIsViewOpen }: PageContentProps) {
  const theme = useTheme()
  return (
    <>
      <Intro bgColor='purple100'>
        <Container>
          <PageContent>
            <h1>Thankyou!</h1>
          </PageContent>
        </Container>
      </Intro>
      <Container>
        <PageContent>
          <section>
            <p className='cta'>
              Discover how your contact centre can harness the power of daisee's Technology by reading our e-book
            </p>
            <Button action='Open E-Book' btnHeight='auto' bgColor={theme.colors.green} onClick={toggleIsViewOpen} />
            <Img imgStyle={{ objectFit: 'contain' }} fluid={cover} />
            {isViewerOpen && <PDFViewer pdfPath={pdf} onDownload={trackEvent} onClose={toggleIsViewOpen} />}
          </section>
        </PageContent>
      </Container>
    </>
  )
}

export default Thankyou
